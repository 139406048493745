body{
    background: #242735;
    padding: 0;
    margin: 0;
}
.clear{
    clear: both;
}
.header{
    background:#11131b;
    color:#fff;
    height: 60px;
    line-height: 60px;
    width: 90%;
    padding: 0 5%;
}
.header-left{
    float: left;
}
.header-right{
    float: left;
}

.sign-button{
    cursor: pointer;
    background: #fff;
    color:#11131b;
    border-radius: 6px;
    width: 390px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    margin:0 auto;
    font-size: 14px;
}
.sign-button.blue{
    background: #046cfc;
    color:#fff;
}

.login-bottom{
    margin-top:20px;
}
.login-bottom-wrapper{
    padding-top:20px;
    color: #6b7185;
    border-top: 1px solid #3a3f50;
    font-size: 13px;
}
.login-bottom-wrapper-a{
    color: #0066f4;
    padding-left:10px;
}

.footer{
    color:#fff;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    margin-top:50px;
    margin-bottom: 60px;
}

.sign-icon{
    width: 180px;
    height: 180px;
    display: block;
    margin: 0 auto 100px;
}


.provision-addr{
    padding-top:20px;
    color: #fff;
    font-size: 15px;
    margin-bottom:20px;
}
.provision-bottom{
    margin-top:20px;
    margin-bottom:60px;
}
.provision-bottom-wrapper{
    padding-top:20px;
    color: #6b7185;
    border-top: 1px solid #3a3f50;
    font-size: 13px;
}

.errmsg{
    margin-top: 28px;
    background: #282b39;
    border:1px solid #3a3f50;
    border-radius: 12px;
    padding:28px 32px;
    color:#fff;
}

.petinfo{
    margin-top:-90px;
}

.petinfo .petinfo_pic{
    width: 220px;
    height: 220px;
    display: block;
    margin: 0 auto 20px;
    border-radius: 6px;
}
.petinfo .petinfo_ul{
    margin-bottom: 50px;
    color:#fff;
    background: #282b39;
    border:1px solid #3a3f50;
    border-radius: 12px;
    padding:28px 32px;
}
.petinfo .petinfo_ul .petinfo_li{
    padding:6px 0;
}
.petinfo .petinfo_ul .petinfo_li .petinfo_li_label{
    float: left;
    font-size: 14px;
    font-weight: 500;
    color:#a1a6b6;
}
.petinfo .petinfo_ul .petinfo_li .petinfo_li_value{
    margin-left:10px;
    float: right;
    font-size: 14px;
}
.textline_one{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.textline_two{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.transaction-code{
    width: 95%;
    padding:15px 2.5%;
    border-radius: 6px;
    border:0;
    font-size: 13px;
    outline: none;
    margin-bottom:20px;
    background: #fff;
    margin: 0 auto 20px;
    word-break: break-all;
    text-align: left;
   }
.nft-box{
    text-align: left;
}
.nft-box .nft-box-item{
    display: inline-block;
    vertical-align: middle;
    width: 185px;
    background: #fff;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
    
    margin-bottom: 20px;
}
.nft-box .nft-box-item:nth-child(2n){
   margin-right: 0;
}
.nft-box .nft-box-item .nft-box-item-thumb{
    width: 185px;
    height: 185px;
}
.nft-box .nft-box-item .nft-box-item-name{
    font-size: 13px;
    height: 32px;
    padding:10px 10px 0;
    font-weight: 500;
    overflow: hidden;
}
.nft-box .nft-box-item .nft-box-item-info{
    font-size: 12px;
    padding:6px 10px 0;
    color:#787878;
}
.nft-box .nft-box-item .nft-box-item-btn{
    color:#fff;
    background: #ee4f4f;
    display: block;
    margin: 10px auto 20px;
    border:0;
    font-size: 12px;
    border-radius: 4px;
}
.nft-nodata{
    color:#fff;
    text-align: center;
    padding:20px 0;
}
@-webkit-keyframes bounce-down {
    25% {-webkit-transform: translateY(-10px);}
    50%, 100% {-webkit-transform: translateY(0);}
    75% {-webkit-transform: translateY(10px);}
   }

   @keyframes bounce-down {
    25% {transform: translateY(-10px);}
    50%, 100% {transform: translateY(0);}
    75% {transform: translateY(10px);}
   }


   @-webkit-keyframes bounce-up {
    25% {-webkit-transform: translateY(10px);}
    50%, 100% {-webkit-transform: translateY(0);}
    75% {-webkit-transform: translateY(-10px);}
   }

   @keyframes bounce-up {
    25% {transform: translateY(10px);}
    50%, 100% {transform: translateY(0);}
    75% {transform: translateY(-10px);}
   }
.animate-bounce-up{ 
    -webkit-animation: bounce-up 1.5s linear infinite;
    animation: bounce-up 1.5s linear infinite;
   }
/* 大型设备(大台式电脑，1200px 起)*/
@media screen and (min-width:1200px) {

}
 /* 中型设备(台式电脑，992px 起)*/
@media screen and (min-width:992px) and (max-width:1200px) { 

}
 
/* 小型设备(平板电脑，768px 起)*/
@media screen and (min-width:768px) and (max-width:992px) {
    .top-section-wrapper-leftnav{
        display: none;
    }
    .top-section-wrapper-left-btn{
        display: inline-block;
    }
    .sign-icon{
        width: 6rem;
        height: 6rem;
        margin-bottom:3rem;
    }
    .sign-button{
        width: 80%;
        margin: 0 auto;
    }
    .login-bottom{
        width: 80%;
        margin: 20px auto 0 ;
    }
}
 
/* 超小设备(手机，小于 768px)*/
@media screen and (max-width:768px) {
    .top-section-wrapper-leftnav{
        display: none;
    }
    .top-section-wrapper-left-btn{
        display: inline-block;
    }
    .sign-icon{
        width: 6rem;
        height: 6rem;
        margin-bottom:3rem;
    }
    .sign-button{
        width: 80%;
        margin: 0 auto;
    }
    .login-bottom{
        width: 80%;
        margin: 20px auto 0 ;
    }
}
